import { useAtom } from "jotai";
import * as GridAtoms from "../Atoms/WorkAssignmentGridAtoms";
import { Settings } from "types/Settings";
import { AutoCompleteValues } from "types/AutoCompleteValues";
import { getMatchingValues } from "./StringUtil";

export const useResetRightFilter = () => {

    const [, setselectedplanningPlantRightFilter] = useAtom(GridAtoms.selectedPlanningPlantRightFilterAtom);
    const [, setselectedMaintenancePlantRightFilterAtom] = useAtom(GridAtoms.selectedMaintenancePlantRightFilterAtom);
    const [, setselectedWorkcenterRightFilter] = useAtom(GridAtoms.selectedWorkcenterRightFilterAtom);
    const [, setSelectedFloc] = useAtom(GridAtoms.selectedFlocAtom);
    const [, setSelectedProUnits] = useAtom(GridAtoms.selectedProUnitsAtom);
    const [, setSelectedPlannerGroups] = useAtom(GridAtoms.selectedPlannerGroupsAtom);
    const [, setSelectedPriorities] = useAtom(GridAtoms.selectedPrioritiesAtom);
    const [, setSelectedOrderTypes] = useAtom(GridAtoms.selectedOrderTypesAtom);
    const [, setSelectedRevisionNumbers] = useAtom(GridAtoms.selectedRevisionNumbersAtom);
    const [, setSelectedSystemConditions ] = useAtom(GridAtoms.selectedSystemConditionsAtom);
    const [, setselectedSystemStatus ] = useAtom(GridAtoms.selectedSystemStatusAtom);
    const [, setSelectedGenericFields] = useAtom(GridAtoms.selectedGenericFieldsAtom);
    const [, setSelectedTeamName] = useAtom(GridAtoms.selectedTeamNameAtom);
    const [, setAssigned] = useAtom(GridAtoms.assignedAtom);
    const [, setNotAssigned] = useAtom(GridAtoms.notAssignedAtom);
    const [, setStatutoryWork] = useAtom(GridAtoms.statutoryWorkAtom);
    const [, setProcessSafety] = useAtom(GridAtoms.processSafetyAtom);
    const [, setFunctionalSafety] = useAtom(GridAtoms.functionalSafetyAtom);

    const resetRightFilter = () => {
        setselectedplanningPlantRightFilter([]);
        setselectedMaintenancePlantRightFilterAtom([]);
        setselectedWorkcenterRightFilter([]);
        setSelectedFloc([]);
        setSelectedProUnits([]);
        setSelectedPlannerGroups([]);
        setSelectedPriorities([]);
        setSelectedOrderTypes([]);
        setSelectedRevisionNumbers([]);
        setSelectedSystemConditions([]);
        setselectedSystemStatus([]);
        setSelectedGenericFields([]);
        setSelectedTeamName([]);
        setAssigned(false);
        setNotAssigned(false);
        setStatutoryWork(false);
        setProcessSafety(false);
        setFunctionalSafety(false);
    };

    return resetRightFilter;
};
export const updateSelectedItems = (
    settings: Settings[],
    items: AutoCompleteValues[],
    setSelectedItems: React.Dispatch<React.SetStateAction<AutoCompleteValues[]>>
  ) => {
    if (settings && settings.length > 0) {
      const selectedItems = getMatchingValues(
        items ?? [],
        settings.map(({ settingValue }) => settingValue)
      );

      setSelectedItems((prevSelectedItems) => {
        const updatedItems = new Set([...prevSelectedItems, ...selectedItems]);
        const uniqueItemsMap = new Map<string, AutoCompleteValues>();

        updatedItems.forEach((item) => uniqueItemsMap.set(item.id, item));

        return Array.from(uniqueItemsMap.values());
      });
    }
  };

import { Checkbox, FormControlLabel, FormGroup, FormLabel, List } from "@mui/material";
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import * as GridAtoms from '../../Atoms/WorkAssignmentGridAtoms';
import MultiSelectDropdown from "../Common/MultiSelectDropdown";
import { useTranslation } from "react-i18next";
import { MultiSelectDropdownProps } from '../../types/MultiSelectDropDownProps';
import { useFetchMaintenancePlants, useFetchMaintenancePlantsForCode, useFetchPlanningPlants, useFetchPlanningPlantsForCode, useFetchWorkCenters, useFetchWorkCentersFromCode } from "hooks/WorkAssignmentHooks";
import { enrichedUniqueValues, enrichedUniqueValuesUsingCode } from "utilities/ExtractUniqueValues";



const GridRightFilters: React.FC = () => {

  const WidthDrawerSelect='90%';
	const MarginLeftDrawerSelect=2;
  const [t, ] = useTranslation();
	const MarginBottomDrawerSelect=3;
  const [workAssignmentData, setWorkAssignmentData] = useAtom(GridAtoms.workAssignmentDataAtom);
  const [initialWAData] = useAtom(GridAtoms.initialWADataAtom);
  const [PlanningPlantRightFilter] = useAtom(GridAtoms.planningPlantRightFilterAtom); 
  const [maintenancePlantRightFilter] = useAtom(GridAtoms.maintenancePlantRightFilterAtom);
  const [workcenterRightFilter] = useAtom(GridAtoms.workcenterRightFilterAtom);
  const [FunctionalLocations] = useAtom(GridAtoms.functionalLocationsAtom); 
  const [ProductiveUnits] = useAtom(GridAtoms.productiveUnitsAtom);
  const [PlannerGroups] = useAtom(GridAtoms.plannerGroupsAtom);
  const [Priorities] = useAtom(GridAtoms.prioritiesAtom);
  const [OrderTypes] = useAtom(GridAtoms.orderTypesAtom);
  const [SystemCondition] =useAtom(GridAtoms.systemConditionAtom);
  const [SystemStatus] =useAtom(GridAtoms.systemStatusAtom);
  const [RevisionNumbers] =useAtom(GridAtoms.revisionNumbersAtom);
  const [GenericFields] = useAtom(GridAtoms.genericFieldsAtom);
  const [TeamNames] = useAtom(GridAtoms.teamNameAtom);

  const [selectedplanningPlantRightFilter, setselectedplanningPlantRightFilter] = useAtom(GridAtoms.selectedPlanningPlantRightFilterAtom);
  const [selectedMaintenancePlantRightFilter, setselectedMaintenancePlantRightFilterAtom] = useAtom(GridAtoms.selectedMaintenancePlantRightFilterAtom);
  const [selectedWorkcenterRightFilter, setselectedWorkcenterRightFilter] = useAtom(GridAtoms.selectedWorkcenterRightFilterAtom);
  const [selectedFloc, setSelectedFloc] = useAtom(GridAtoms.selectedFlocAtom);
  const [selectedProUnits, setSelectedProUnits] = useAtom(GridAtoms.selectedProUnitsAtom);
  const [selectedPlannerGroups, setSelectedPlannerGroups] = useAtom(GridAtoms.selectedPlannerGroupsAtom);
  const [selectedPriorities, setSelectedPriorities] = useAtom(GridAtoms.selectedPrioritiesAtom)
  const [selectedOrderTypes, setSelectedOrderTypes] = useAtom(GridAtoms.selectedOrderTypesAtom); 
  const [selectedRevisionNumbers, setSelectedRevisionNumbers] = useAtom(GridAtoms.selectedRevisionNumbersAtom);
  const [selectedSystemConditions, setSelectedSystemConditions ] = useAtom(GridAtoms.selectedSystemConditionsAtom);
  const [selectedSystemStatus, setselectedSystemStatus ] = useAtom(GridAtoms.selectedSystemStatusAtom);
  const [selectedGenericFields, setSelectedGenericFields] = useAtom(GridAtoms.selectedGenericFieldsAtom);
  const [selectedTeamName, setSelectedTeamName] = useAtom(GridAtoms.selectedTeamNameAtom);

  // Checkbox atoms
  const [assigned, setAssigned] = useAtom(GridAtoms.assignedAtom);
  const [notAssigned, setNotAssigned] = useAtom(GridAtoms.notAssignedAtom);
  const [statutoryWork, setStatutoryWork] = useAtom(GridAtoms.statutoryWorkAtom);
  const [processSafety, setProcessSafety] = useAtom(GridAtoms.processSafetyAtom);
  const [functionalSafety, setFunctionalSafety] = useAtom(GridAtoms.functionalSafetyAtom);

 
  const { data: planningPlants,isLoading:isPPLoading } =
    useFetchPlanningPlantsForCode(PlanningPlantRightFilter.map(({ id }) => id));
  const { data: maintenancePlants,isLoading:isMPLoading } =
    useFetchMaintenancePlantsForCode(maintenancePlantRightFilter.map(({ id }) => id));
  // get the Planning Plants, Maintenance Plants and Work Centers based on code from the work assignment data
  const { data: workCenters,isLoading:isWCLoading } = useFetchWorkCentersFromCode(workcenterRightFilter.map(({ id }) => id));
  

  const filters:Array<MultiSelectDropdownProps> = [
    {
      label: t("PlanningPlantsMultipleText"),
      selectedValues: planningPlants?.filter(o => selectedplanningPlantRightFilter.includes(o.id))??[],
      options: planningPlants??[],
      onChange: setselectedplanningPlantRightFilter,
      required: false,
      isLoading:isPPLoading,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect,
      concateText: true
    },
    {
      label: t("WorkCentresText"),
      selectedValues: workCenters?.filter(o => selectedWorkcenterRightFilter.includes(o.id))??[],
      options: workCenters??[],
      onChange: setselectedWorkcenterRightFilter,
      required: false,
      isLoading:isWCLoading,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect,
      concateText: true,
      renderCode: true
    },
    {
      label: t("MaintenancePlantsText"),
      selectedValues: maintenancePlants?.filter(o => selectedMaintenancePlantRightFilter.includes(o.id))??[],
      options: maintenancePlants??[],
      onChange: setselectedMaintenancePlantRightFilterAtom,
      required: false,
      isLoading:isMPLoading,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect,
      concateText: true
    },    
    {
      label: t("FunctionalLocationText"),
      selectedValues: FunctionalLocations.filter(o => selectedFloc.includes(o.id)),
      options: FunctionalLocations,
      onChange: setSelectedFloc,
      required: false,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect
    },
    {
      label: t("ProductiveUnitText"),
      selectedValues: ProductiveUnits.filter(o => selectedProUnits.includes(o.id)),
      options: ProductiveUnits,
      onChange: setSelectedProUnits,
      required: false,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect
    },
    {
      label: t("PlannerGroupText"),
      selectedValues: PlannerGroups.filter(o => selectedPlannerGroups.includes(o.id)),
      options: PlannerGroups,
      onChange: setSelectedPlannerGroups,
      required: false,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect
    },
    {
      label: t("PriorityText"),
      selectedValues: Priorities.filter(o => selectedPriorities.includes(o.id)), 
      options: Priorities,
      onChange: setSelectedPriorities,
      required: false,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect
    },
    {
      label: t("OrderTypeText"),
      selectedValues: OrderTypes.filter(o => selectedOrderTypes.includes(o.id)),
      options: OrderTypes,
      onChange: setSelectedOrderTypes,
      required: false,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect
    },
    {
      label: t("RevisionNumberText"),
      selectedValues: RevisionNumbers.filter(o => selectedRevisionNumbers.includes(o.id)),
      options: RevisionNumbers,
      onChange: setSelectedRevisionNumbers,
      required: false,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect
    },
    {
      label: t("SystemConditionText"),
      selectedValues: SystemCondition.filter(o => selectedSystemConditions.includes(o.id)) ,
      options:  SystemCondition,
      onChange: setSelectedSystemConditions,
      required: false,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect
    },
    {
      label: t("SystemStatusText"),
      selectedValues: SystemStatus.filter(o => selectedSystemStatus.includes(o.id)),
      options: SystemStatus,
      onChange: setselectedSystemStatus,
      required: false,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect
    },
    {
      label: t("TeamNameText"),
      selectedValues: TeamNames.filter(o => selectedTeamName.includes(o.id)),
      options: TeamNames,
      onChange: setSelectedTeamName,
      required: false,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect
    },
    {
      label: t("GenericFieldText"),
      selectedValues: GenericFields.filter(o => selectedGenericFields.includes(o.id)),
      options: GenericFields,
      onChange: setSelectedGenericFields,
      required: false,
      width: WidthDrawerSelect,
      marginLeft: MarginLeftDrawerSelect,
      marginBottom: MarginBottomDrawerSelect
    },
  ];

  useEffect(() => {    
		let filteredData = initialWAData;
    if (selectedplanningPlantRightFilter.length) {      
			filteredData = filteredData.filter(operation => selectedplanningPlantRightFilter.includes(operation.planningPlantId));
		}
    if (selectedMaintenancePlantRightFilter.length) {     
			filteredData = filteredData.filter(operation => selectedMaintenancePlantRightFilter.includes(operation.maintenancePlantId));
		}
    if (selectedWorkcenterRightFilter.length) {
      console.log("selectedWorkcenterRightFilter",selectedWorkcenterRightFilter);
      console.log(filteredData);
			filteredData = filteredData.filter(operation => selectedWorkcenterRightFilter.includes(operation.workCenter));
		}
		if (selectedFloc.length) {
			filteredData = filteredData.filter(operation => selectedFloc.includes(operation.functionalLocation));
		}
		if (selectedProUnits.length) {
			filteredData = filteredData.filter(operation => selectedProUnits.includes(operation.productiveUnit));
		}
		if (selectedPlannerGroups.length) {
			filteredData = filteredData.filter(operation => selectedPlannerGroups.includes(operation.plannerGroup));
		}
		if (selectedPriorities.length) {
			filteredData = filteredData.filter(operation => selectedPriorities.includes(operation.priority));
		}
		if (selectedOrderTypes.length) {
			filteredData = filteredData.filter(operation => selectedOrderTypes.includes(operation.orderType));
		}
		if (selectedRevisionNumbers.length) {
			filteredData = filteredData.filter(operation => selectedRevisionNumbers.includes(operation.revision));
		}
    if (selectedSystemConditions.length) {
			filteredData = filteredData.filter(operation => selectedSystemConditions.includes(operation.systemCondition));
		}
    if (selectedSystemStatus.length) {
			filteredData = filteredData.filter(operation => selectedSystemStatus.includes(operation.systemStatus));
		}
    if (selectedTeamName.length) {
			filteredData = filteredData.filter(operation => selectedTeamName.includes(operation.teamName));
		}
		if (selectedGenericFields.length) {
			filteredData = filteredData.filter(operation => selectedGenericFields.includes(operation.genericField));
		}
    if (assigned && !notAssigned) {
      filteredData = filteredData.filter(operation => operation.assignedUsers.length>0);
    }
    if (!assigned && notAssigned) {
      filteredData = filteredData.filter(operation => operation.assignedUsers.length===0);
    }
    if (statutoryWork) {     
      filteredData = filteredData.filter(operation => operation.systemAlerts.includes("Statutory"));
    }
    if (processSafety) {
      filteredData = filteredData.filter(operation => operation.systemAlerts.includes("ProcessSafety"));    
    }
    if (functionalSafety) {
      filteredData = filteredData.filter(operation => operation.systemAlerts.includes("FunctionalSafety"));       
    }
		setWorkAssignmentData(filteredData);
	}, [selectedFloc, selectedProUnits, selectedPlannerGroups, selectedPriorities, selectedOrderTypes, selectedRevisionNumbers, selectedGenericFields, selectedTeamName, setWorkAssignmentData, selectedplanningPlantRightFilter, selectedMaintenancePlantRightFilter, selectedWorkcenterRightFilter, assigned, notAssigned, statutoryWork, processSafety, functionalSafety, selectedSystemConditions, selectedSystemStatus]);
  
  return (
    <List>
      {filters.map((filter) => (
        <MultiSelectDropdown
          key={filter.label}
          label={filter.label}
          selectedValues={filter.selectedValues}
          options={filter.options}
          onChange={filter.onChange}
          required={filter.required}
          width={filter.width}
          marginLeft={filter.marginLeft}
          marginBottom={filter.marginBottom}
          concateText={filter.concateText}
          renderCode={filter.renderCode}
        />
      ))}
      <FormGroup sx={{marginLeft:3}}>
        <FormLabel component="legend" sx={{fontSize:14,fontWeight:500}}>{t("AssignmentTypeText")}</FormLabel>
        <FormControlLabel
          control={<Checkbox checked={assigned} onChange={(e) => setAssigned(e.target.checked)} />}
          label={t("AssignedText")}/>
        <FormControlLabel control={<Checkbox checked={notAssigned} onChange={(e) => setNotAssigned(e.target.checked)}  />} label={t("NotAssignedText")} />    
      </FormGroup>
      <FormGroup sx={{marginLeft:3}}>
        <FormLabel component="legend" sx={{fontSize:14,fontWeight:500}}>{t("WorkOrderAlertsText")}</FormLabel>
        <FormControlLabel
          control={<Checkbox checked={statutoryWork} onChange={(e) => setStatutoryWork(e.target.checked)} />}
          label={t("StatutoryWorkText")}
        />
        <FormControlLabel
          control={<Checkbox checked={processSafety} onChange={(e) => setProcessSafety(e.target.checked)} />}
          label={t("ProcessSafetyText")}
        />
        <FormControlLabel
          control={<Checkbox checked={functionalSafety} onChange={(e) => setFunctionalSafety(e.target.checked)} />}
          label={t("FunctionalSafetyText")}
        />
      </FormGroup>
    </List> 
  );
}

export default GridRightFilters;

import { AutoCompleteValues } from "types/AutoCompleteValues";

export const extractUniqueValues = <T extends Record<string, any>>(
  workAssignments: T[],
  key: keyof T
) => {
  const uniqueValues = new Set<string>();
  workAssignments.forEach((item) => {
    if (item !== null && item !== undefined && item[key] !== null && item[key] !== undefined && item[key]!=="") {
      const value = item[key];
      uniqueValues.add(value.toString());
    }
  });
  return Array.from(uniqueValues).map((value) => ({ name: value, id: value }));
};

export const enrichedUniqueValues = (
  values: AutoCompleteValues[],
  details: AutoCompleteValues[]
) => {
  const ids = values.map(v => v.id);
  const detailedValues = details.filter(v => ids.includes(v.id));
  if(detailedValues){
    return detailedValues;
  }
  return values;
};

export const enrichedUniqueValuesUsingCode = (
  values: AutoCompleteValues[],
  details: AutoCompleteValues[]
) => {
  const ids = values.map(v => v.id);
  const detailedValues = details.filter(v => ids.includes(v.code ?? v.id)).map(v => ({
    id: v.code ?? v.id,
    name: v.name,
    code: v.code
  }));
  if(detailedValues){
    return detailedValues;
  }
  return values;
};


